.announcement {

}

.announcement__banner {
 width: 100%;
 height: 200px;
 object-fit: cover;
}

.announcement__body {
    padding: 15px;
    margin-bottom: 30px;
}

.announcement h1 {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
}

.announcement h5 {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 20px;
}

.announcement .date {
    color: #A0A0A4;
    font-size: 14px;
    margin-bottom: 15px;
}

.announcement-content {
    font-size: 17px;
    margin-bottom: 20px;
}
