p {
	margin: 0;
	margin-bottom: 10px;
}

audio {
	outline: none;
}
.overlay {
	position: fixed;
	top: 60px;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0,0,0,.5);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;
	cursor: pointer;
}

.overlay > div {
	text-align: center;
	font-size: 18px;
}

@media (max-width: 768px) {
	.App {
		height: calc(100% - 60px);
	}
}

a {
	text-decoration: none;
}

.player-wrapper {
	position: relative;
	padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */

}

.player-wrapper .react-player {
	position: absolute;
	top: 0;
	left: 0;
}



.player-audio-wrapper {
	position: relative;
	padding: 10px;
}

.editor__editable,
	/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
	/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
	/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	min-height: 400px;
	padding: 1em;
}